import React from 'react';
import { Link, graphql, HeadProps, PageProps } from 'gatsby';
import { Col, Row, Container } from 'react-bootstrap';
import Breadcrumb from '../components/Layout/breadcrumb'

import Meta from '../components/Layout/head';
import Layout from '../components/Layout/layout';
import countryDestination from '../components/country/country-destination';
import HikeFilterSearchBox from "../components/Filters/hikes-filters/hike-filter-searchbox";
import HikeResultFilter from "../components/Filters/hikes-filters/hike-results-filter";
import ParkGallery from '../components/Park/park-gallery';
import Carousel from '../components/UI/Carousel/Carousel';
import ParkCell from '../components/Park/ParkCard';
import UrbanAreaAround from '../components/HikeUrbanarea/UrbanareaListing';
import RichText from '../components/seo/rich-text';

import BannerLeaderboard from '../components/Banners/BannerLeaderboard';
import BannerPortrait from '../components/Banners/BannerPortrait';
import HikeUrbanAreaAccommodations from '../components/HikeUrbanarea/HikeUrbanAreaTemplate/HikeUrbanAreaAccommodations';


export const Head = ({data}: HeadProps<Queries.HikeUrbanAreaTemplateQuery>) => {
    const hikeUrbanArea = data.contentfulHikeUrbanArea;
    const hikeUrbanAreaName = hikeUrbanArea?.title;
    const country = countryDestination(hikeUrbanArea?.countryCode);
    const pathname = `/${country}/${hikeUrbanArea?.slug}/`;
  
    return (
      <Meta 
        title={`Top +20 Randonnées à ${hikeUrbanAreaName}`} 
        description={`Envie de randonner autour de ${hikeUrbanAreaName} ? Voici un top des meilleures randonnées à réaliser dans les environs…`}
        lang={'fr'}
        pathname={pathname}
        media={`https:${hikeUrbanArea?.cover?.file?.url}`}
      > 
      </Meta>
    )
}


export default function HikeUrbanAreaTemplate({data, location}: PageProps<Queries.HikeUrbanAreaTemplateQuery>) {

    // Filters
    const [selectedDifficulties, setSelectedDifficulties] = React.useState<string[]>([]);
    const [selectedDogStatus, setSelectedDogStatus] = React.useState<string[]>([]);
    const [selectedRanking, setSelectedRankings] = React.useState<string[]>([]);
    const [selectedDuration, setSelectedDuration] = React.useState<(number | number[])>([0, 24]);
    const [selectedElevation, setSelectedElevation] = React.useState<(number | number[])>([0, 2000]);
    const [selectedDistance, setSelectedDistance] = React.useState<(number | number[])>([0, 50]);
    const [isURLParamChecked, setIsURLParamChecked] = React.useState(false);
    
    // Globals data variables
    const hikeUrbanArea = data.contentfulHikeUrbanArea;
    const allHikesUrbanArea = data.allContentfulHikeUrbanArea;
    const urbanAreaHikesGallery = data.hikesGallery;
    const urbanAreaHikesList = data.hikesList;

    // Hike Urban Area variables 
    const hikeUrbanAreaName = hikeUrbanArea?.title;
    const hikeUrbanAreaIntroduction = hikeUrbanArea?.introduction?.introduction;
    const hikeUrbanAreaSlug = hikeUrbanArea?.slug;

    // Breadcrumb
    const country = countryDestination(hikeUrbanArea?.countryCode);
    const pathHikeUrbanArea = `/${country}/randonnees/${hikeUrbanAreaSlug}/`;
    const breadcrumbArray = [[pathHikeUrbanArea,hikeUrbanAreaName]];


    function ParksAround() {
      const hikeUrbanAreaParks = hikeUrbanArea?.parks;

      if(hikeUrbanAreaParks) {

        const listOfCards = hikeUrbanAreaParks.flatMap((park) => park ? [park] : []) // Filter null elements
          .map((park) => (
            <ParkCell park={park} key={park?.id} />
          )
        )

        const carouselSettings = {
          responsive: {
            breakpoint1500: {
              slidesToShow: 3.2,
            },
            breakpoint1024: {
                slidesToShow: 2.4,
            },
            breakpoint768: {
                slidesToShow: 1.7,
                arrows: false
            },
            breakpoint480: {
                slidesToShow: 1.4,
                arrows: false
            }
          },
          className: "center",
          centerPadding: "60px",
          slidesToShow: 4.2,
          slidesToScroll: 3,
          infinite: false,
        }

        const carouselProps = { listOfCards, carouselSettings }

        return (
          <div className="mt-3">
              <h2>Parcs et réserves autour de {hikeUrbanAreaName}</h2>
              <Link to={`/randonnees/`}>Voir tous les parcs naturels où randonner ‣</Link>
              <Carousel {...carouselProps}/>
          </div>
        )
      }
      return null

    }
  
    return (
      <Layout>
        <Container fluid>
            <Row className='banner-leaderboard'>
                <Col sm='12' md={{span:10, offset:1}} lg={{span:8, offset:2}}>
                  <BannerLeaderboard/>
                </Col>
            </Row>
        </Container>  

        <Container className='pt-2'>
            <Breadcrumb location={location} itemsArray={breadcrumbArray} />
            <div className='text-center'>
                <h1>Randonnées à {hikeUrbanAreaName}</h1>
            </div>
        </Container>

        <Container>
            <Row>
                <Col className='pb-4'>
                    <ParkGallery dataSlider={urbanAreaHikesList} dataGallery={urbanAreaHikesGallery} />
                    <p className='text-center'>{hikeUrbanAreaIntroduction}</p>
                </Col>
            </Row>
        </Container>


        <Container className='container-md'>
                <Row>
                    <Col xs='12' md='8' lg='9'>
                        <h2 className="mb-3">Randonnées autour de {hikeUrbanAreaName}</h2>
                        <HikeFilterSearchBox 
                            location={location} 
                            hikes={urbanAreaHikesList} 
                            selectedDifficulties={selectedDifficulties}
                            selectedDogStatus={selectedDogStatus}
                            selectedRanking={selectedRanking}
                            selectedDuration={selectedDuration}
                            selectedElevation={selectedElevation}
                            selectedDistance={selectedDistance}
                            setSelectedDifficulties={setSelectedDifficulties}
                            setSelectedDogStatus={setSelectedDogStatus}
                            setSelectedRankings={setSelectedRankings}
                            setSelectedDuration={setSelectedDuration}
                            setSelectedElevation={setSelectedElevation}
                            setSelectedDistance={setSelectedDistance}
                            isURLParamChecked={isURLParamChecked}
                        />
                        <Row>
                            <HikeResultFilter 
                                location={location} 
                                hikes={urbanAreaHikesList} 
                                setSelectedDifficulties={setSelectedDifficulties}
                                setSelectedDogStatus={setSelectedDogStatus}
                                setSelectedRankings={setSelectedRankings}
                                setSelectedDuration={setSelectedDuration}
                                setSelectedElevation={setSelectedElevation}
                                setSelectedDistance={setSelectedDistance}
                                setIsURLParamChecked={setIsURLParamChecked}
                                isURLParamChecked={isURLParamChecked}
                            />
                        </Row>
                        <HikeUrbanAreaAccommodations slug={hikeUrbanAreaSlug} urbanArea={hikeUrbanAreaName} />
                        <ParksAround />

                        <RichText content={hikeUrbanArea?.seoContent} />
                        <UrbanAreaAround hikeUrbanArea={hikeUrbanArea} allHikesUrbanArea={allHikesUrbanArea} location={location} />
                    </Col>

                    <Col xs='12' md='4' lg='3'>
                      <BannerPortrait/>
                    </Col>

                </Row>
            </Container>

            <Container>
                <Row>
                    <Col xs="12">
        </Col>
                </Row>
            </Container>
            
      </Layout>
    )
  }
  
export const HikeUrbanAreaTemplateQuery = graphql`
  query HikeUrbanAreaTemplate($slug: String!) {
    contentfulHikeUrbanArea(slug: {eq: $slug}) {
      ...OneHikeUrbanArea
    }
    allContentfulHikeUrbanArea(filter: {node_locale: {eq: "fr"}}, limit: 10) {
      ...AllHikesUrbanAreaList
    }
    hikesGallery: allContentfulHike(
      filter: {hikeUrbanArea: {elemMatch: {slug: {eq: $slug}}}, node_locale: {eq: "fr"}}
      limit: 30
      sort: {rank: ASC}
    ) {
      nodes {
        cover {
          description
          gatsbyImageData(
            formats: [AUTO, WEBP]
            quality: 30
            placeholder: BLURRED
            jpegProgressive: true
          )
          file {
            fileName
            url
          }
          id
        }
        title
        slug
        id
        countryCode
        hikeUrbanArea {
          slug
          location {
            lat
            lon
          }
          countryCode
          id
        }
      }
    }
    hikesGallery: allContentfulHike(
      filter: {hikeUrbanArea: {elemMatch: {slug: {eq: $slug}}}, node_locale: {eq: "fr"}}
      limit: 30
      sort: {rank: ASC}
    ) {
      nodes {
        cover {
          description
          gatsbyImageData(
            formats: [AUTO, WEBP]
            quality: 30
            placeholder: BLURRED
            jpegProgressive: true
          )
          file {
            fileName
            url
          }
          id
        }
        title
        slug
        id
        countryCode
        hikeUrbanArea {
          slug
        }
      }
    }
    hikesList: allContentfulHike(
      filter: {hikeUrbanArea: {elemMatch: {slug: {eq: $slug}}}, node_locale: {eq: "fr"}}
      limit: 100
      sort: {rank: ASC}
    ) {
      nodes {
        countryCode
        difficultyLevel
        distance
        duration
        elevation
        cover {
          description
          gatsbyImageData(
            formats: [AUTO, WEBP]
            quality: 30
            resizingBehavior: THUMB
            placeholder: BLURRED
            width: 612
            height: 440
            jpegProgressive: true
            outputPixelDensities: 0.5
          )
        }
        slug
        hikeUrbanArea {
          slug
        }
        title
        rank
        dogs
        title
        slug
        id
        park {
          title
          slug
          countryCode
          cover {
            description
            gatsbyImageData(
              formats: [AUTO, WEBP]
              quality: 30
              resizingBehavior: FILL
              placeholder: BLURRED
              width: 604
              height: 440
              jpegProgressive: true
            )
          }
          id
        }
      }
    }
  }
`