import React from 'react';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import parse from 'html-react-parser';


export default function RichText(props) {
    const content = props.content?.raw ? parse(documentToHtmlString(JSON.parse(props.content.raw))) : '';
    return (
        <div className='mt-3'>
            {content}
        </div>
    );
}