import React from 'react';
import { Link, PageProps } from 'gatsby';
import countryDestination from '../country/country-destination';


interface Props {
    hikeUrbanArea: Queries.OneHikeUrbanAreaFragment | null,
    allHikesUrbanArea: Queries.AllHikesUrbanAreaListFragment | null,
    location: PageProps['location']
}

export default function UrbanAreaAround(props: Props) {
    
    // Globals data variables
    const hikeUrbanArea = props.hikeUrbanArea;
    const allHikesUrbanArea = props.allHikesUrbanArea;

    const UrbanAreaList = () => {
        const list: JSX.Element[] = [];
        allHikesUrbanArea?.nodes.forEach((urbanArea, index) => {
            const countryUrbanArea = countryDestination(urbanArea.countryCode);
            if(urbanArea.title !== hikeUrbanArea?.title) {
                list.push(
                    <li key={index}>
                        <Link to={`${props.location.origin}/${countryUrbanArea}/randonnees/${urbanArea.slug}/`}>Randonnée à {urbanArea.title}</Link>
                    </li>
                );
            }
        })
        return (
            <>
                {list}
            </>
        );
    }

  
    return (
        <div className='mt-3'>
            <h2>Autres villes où randonner</h2>
            <ul>
                <UrbanAreaList />
            </ul>
        </div>
    )
}