import React from "react";
import AccommodationsCarousel from "../../Accommodations/AccommodationsCarousel";
import { Container, Row, Col } from "react-bootstrap";

interface Props {
  slug?: string | null;
  urbanArea: string | null | undefined;
}

export default function HikeUrbanAreaAccommodations(props: Props) {
  const { slug, urbanArea } = props;

  const setCarouselSettings = {
    responsive: {
      breakpoint2400: {
        slidesToShow: 4.1,
      },
      breakpoint1500: {
        slidesToShow: 3.8,
      },
      breakpoint1300: {
        slidesToShow: 3.5,
      },
      breakpoint1024: {
        slidesToShow: 2.1,
      },
      breakpoint768: {
        slidesToShow: 2.1,
      },
      breakpoint480: {
        slidesToShow: 1.6,
      },
    },
    className: "center",
    centerPadding: "60px",
    slidesToShow: 4.2,
    slidesToScroll: 3,
    infinite: false,
  };

  return (
    <Col>
      <Container>
        <Row>
          <Col>
            <h2>Logements à proximité de {urbanArea}</h2>
            <AccommodationsCarousel
              slug={slug}
              carouselSettings={setCarouselSettings}
            />
            <p>
              Maison, gîtes, appartements et autres locations à proximité de{" "}
              {urbanArea}.
            </p>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}
